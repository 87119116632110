<template>
  <tr>
    <template v-if="loading">
      <td colspan="8" class="text-center">
        <span class="font-weight-bolder" style="font-size: 1.5em">Loading ...</span>
      </td>
    </template>
    <template v-else>
      <td>
        <a class="font-weight-bold">{{ dt.id }}</a>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.CoinType.name }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.amount }}</span>
      </td>
      <td>
        <router-link
          :to="`/coins/sell-coins/${dt.id}`"
          class="font-weight-normal text-blue"
        >{{ dt.reference }}</router-link>
      </td>
      <td>
        <span
          class="font-weight-normal"
          :class="dt.status === 'DONE' ? 'text-success' : ''"
        >{{ dt.status }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.created_at }}</span>
      </td>
      <td>
        <span class="font-weight-normal">{{ dt.description }}</span>
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            :disabled="loading"
          >
            <span class="icon icon-sm">
              <span class="fas fa-ellipsis-h icon-dark"></span>
            </span>
            <span class="sr-only">Toggle Dropdown</span>
          </button>
          <div class="dropdown-menu">
            <router-link :to="`/coins/sell-coins/${dt.id}`" class="dropdown-item text-dark">
              <span class="fas fa-eye mr-1"></span>
              See Details
            </router-link>
          </div>
        </div>
      </td>
    </template>
  </tr>
</template>
  
  <script lang="ts">
import { inject, ref, defineComponent } from "vue";

export default defineComponent({
  name: "SellGiftCardTransactionRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    return {
      loading,
      RequestConfirmation,
    };
  },
});
</script>
  
  <style scoped></style>
  