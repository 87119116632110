
import { inject, ref, defineComponent } from "vue";

export default defineComponent({
  name: "SellGiftCardTransactionRow",
  props: {
    dt: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const RequestConfirmation = inject("RequestConfirmation");

    const loading = ref(false);

    return {
      loading,
      RequestConfirmation,
    };
  },
});
