
import { defineComponent } from "vue";
export default defineComponent({
  name: "CountSelector",
  props: {
    modelValue: Number,
  },
  emits: ["update:modelValue", "updated"],
  setup(_, context) {
    const ChangeValue = (batch: number) => {
      context.emit("update:modelValue", batch);
      context.emit("updated");
    };
    return {
      count: [5, 10, 20, 30, 50],
      ChangeValue,
    };
  },
});
