<template>
  <div class="mt-1">
    <div
      v-if="totalRecord > dataCount"
      class="card-footer px-3 border-0 d-flex align-items-center justify-content-between"
    >
      <nav aria-label="Page navigation example">
        <ul class="pagination mb-0">
          <li
            v-if="currentPage > 0"
            class="page-item"
            @click="ChangePage(currentPage - 1)"
          >
            <a class="page-link">Previous</a>
          </li>
          <template v-for="(item, key) in pages" :key="key">
            <li
              class="page-item"
              :class="item === currentPage ? 'active' : ''"
              @click="ChangePage(item)"
            >
              <a class="page-link">{{ item + 1 }}</a>
            </li>
          </template>
          <li
            v-if="currentPage < parseInt(totalPage.toString())"
            class="page-item"
            @click="ChangePage(currentPage + 1)"
          >
            <a class="page-link">Next</a>
          </li>
        </ul>
      </nav>
      <div class="font-weight-bold small">
        Showing <b>{{ dataCount }}</b> out of <b>{{ totalRecord }}</b> entries
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Pagination",
  props: ["currentPage", "totalRecord", "dataCount"],
  emits: ["update:modelValue", "updated"],
  setup(props, context) {
    const maxPage = 10;
    const totalPage = computed(() => props.totalRecord / props.dataCount);

    const pages = computed(function () {
      const pages = [];
      pages.push(props.currentPage);
      // Recompute for props reactivity
      for (let i = 1; i < maxPage; i++) {
        if (i > totalPage.value) {
          break;
        }

        if (i === props.currentPage) {
          continue;
        }

        pages.push(i);
      }

      pages.sort();

      return pages;
    });

    function ChangePage(newPage: number) {
      console.log(totalPage.value)
      if (newPage === props.currentPage) {
        return;
      }
      context.emit("update:modelValue", newPage);
      context.emit("updated");
    }

    return {
      ChangePage,
      pages,
      totalPage,
    };
  },
});
</script>

<style scoped></style>
