<template>
  <div>
    <button
      class="btn btn-dark btn-sm dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Filter By
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(key, index) in Object.keys(filters)" :key="index">
        <a
          class="dropdown-item"
          @click="ChangeValue(key === 'ALL' ? undefined : key)"
        >
          {{ filters[key] }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ref, defineComponent } from "vue";

export default defineComponent({
  name: "DataFilterSelector",
  props: {
    modelValue: {},
    filters: Object,
  },
  emits: ["update:modelValue", "updated"],
  setup(props, context) {
    const valueCopy = ref(null);
    const ChangeValue = (batch: string | number) => {
      context.emit("update:modelValue", batch);
      context.emit("updated");
    };

    return {
      valueCopy,
      ChangeValue,
    };
  },
});
</script>
