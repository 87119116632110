<template>
  <div class="btn-group">
    <button
      class="btn btn-link text-decoration-none text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="icon icon-sm icon-gray">
        <span class="fas fa-cog"></span>
      </span>
      <span class="sr-only">Toggle Dropdown</span>
    </button>
    <div class="dropdown-menu dropdown-menu-xs dropdown-menu-right">
      <span class="dropdown-item font-weight-bold text-dark">Show</span>
      <template v-for="(batch, index) in count" :key="index">
        <a
          class="dropdown-item d-flex font-weight-bold"
          @click="ChangeValue(batch)"
        >
          {{ batch }}
          <span v-if="batch === modelValue" class="icon icon-small ml-auto">
            <span class="fas fa-check"></span>
          </span>
        </a>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CountSelector",
  props: {
    modelValue: Number,
  },
  emits: ["update:modelValue", "updated"],
  setup(_, context) {
    const ChangeValue = (batch: number) => {
      context.emit("update:modelValue", batch);
      context.emit("updated");
    };
    return {
      count: [5, 10, 20, 30, 50],
      ChangeValue,
    };
  },
});
</script>

<style scoped></style>
