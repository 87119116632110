
import { ref, defineComponent } from "vue";

export default defineComponent({
  name: "DataFilterSelector",
  props: {
    modelValue: {},
    filters: Object,
  },
  emits: ["update:modelValue", "updated"],
  setup(props, context) {
    const valueCopy = ref(null);
    const ChangeValue = (batch: string | number) => {
      context.emit("update:modelValue", batch);
      context.emit("updated");
    };

    return {
      valueCopy,
      ChangeValue,
    };
  },
});
